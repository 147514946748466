:root {
  --container-width: 1440px;
}
@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"),
   url("./assets/fonts/nunito-sans.woff2") format("truetype");

  }

@font-face {
  font-family: "Ubuntu Mono" ;
  src: local("Ubuntu Mono"),
   url("./assets/fonts/ubuntu-mono.woff2") format("truetype");
  }

::-webkit-scrollbar {
  height: 0.25rem;
  width: .25rem;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 999999px;
  background-color: rgba(203, 213, 225, 0.9);
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;

}

a,
li,
button:focus {
  box-shadow: none !important;
}

button:active {
  background: transparent !important;
}

.chakra-ui-light {
  --trading-board: #f5f5f5;
  --ag-row-selected: #dbf2ed;
  --color-up: #4aa165;
  --color-down: #d16547;
  --color-text: #333;
  --color-active: #fff;
  --color-header-ag: #333;
  --sidebar-ag: '#f5f5f5';
  --info-board: #fff;
  --pagination-item-color: #e9eef7;
  --pagination-active-color: #1e4d50;
  --border-color: #ebebeb;
}

.chakra-ui-dark {
  --trading-board: #1e3446;
  --ag-row-selected: #304b61;
  --color-up: #00d341;
  --color-down: #ff4c42;
  --color-text: white;
  --color-active: #333;
  --color-header-ag: #848e9c;
  --sidebar-ag: #2e4960;
  --info-board: #162a3a;
  --pagination-item-color: #405b73;
  --pagination-active-color: #fff;
  --border-color: #162a3a;
}

.text-uptrend {
  color: var(--color-up) !important;
}

.text-downtrend {
  color: var(--color-down) !important;
}
